<template>
  <div class="px-0 lg:px-20">
    <!-- Bar Actions -->
    <div
      class="
        w-full
        py-5
        my-5
        flex flex-row
        justify-center
        md:justify-between
        bg-white
        rounded-cu
        px-2
        mb-5
        shadow-md
      "
    >
      <div>
        <button
          @click="
            modal = true;
            formData = { role: 'PHARMACY-MEMBER' };
          "
          class="
            border-2 border-solid border-nblue
            text-nblue
            py-3
            px-5
            bg-white
            rounded-cu
            font-bold
            text-lg
            cursor-pointer
            transition
            duration-200
            ease-in-out
            hover:bg-nblue
            hover:text-white
            font-EffraR
          "
        >
          Créer un nouvel utilisateur
        </button>
      </div>
    </div>

    <!--Loader -->
    <div class="h-full w-full" v-if="loader">
      <div
        class="
          bg-white bg-opacity-60
          px-8
          py-2
          h-screen
          w-full
          flex
          justify-center
          items-center
        "
      >
        <loader></loader>
      </div>
    </div>

    <!-- List Users -->
    <div v-if="!loader" class="w-full mx-auto">
      <div
        class="
          bg-white
          py-5
          px-2
          rounded-cu
          shadow
          mb-2
          flex flex-col
          lg:flex-row
          lg:justify-between
          lg:items-center
          text-center
          lg:text-left
        "
        v-for="(user, i) in users"
        :key="i"
      >
        <div
          class="
            flex flex-col
            lg:flex-row
            items-center
            w-full
            xl:w-1/2
            mb-3
            xl:mb-0
          "
        >
          <div
            class="
              bg-gray-600
              h-16
              w-16
              border border-solid border-gray-100
              overflow-hidden
              rounded-full
              mr-0
              lg:mr-3
              mb-3
              lg:mb-0
            "
          >
            <img
              alt="profil image"
              :src="$checkAvatar(user.image)"
              onerror="javascript:this.src='/no-avatar.png'"
              class="h-16 w-16 object-cover"
            />
          </div>
          <h3 class="text-lg text-blackdok font-bold font-EffraR">
            {{ `${user.lastName} ${user.firstName}` }}
          </h3>
        </div>
        <div class="flex flex-col w-full xl:w-1/6 mb-3 xl:mb-0">
          <label class="text-base font-bold text-blackdok font-EffraL"
            >Fonction</label
          >
          <span class="font-EffraR text-lg">{{ user.job }}</span>
        </div>
        <div class="flex flex-col w-full xl:w-1/6 mb-3 xl:mb-0">
          <label class="text-base font-bold text-blackdok font-EffraL"
            >Mobile</label
          >
          <span class="font-EffraR text-lg">{{ user.mobile }}</span>
        </div>
        <div class="flex flex-row w-full xl:w-1/6">
          <button
            @click="editUser(user)"
            class="
              border-2 border-solid border-nblue
              text-nblue
              py-2
              px-5
              bg-white
              rounded-cu
              font-bold
              text-base
              cursor-pointer
              transition
              duration-200
              ease-in-out
              hover:bg-nblue
              hover:text-white
              w-full
              mr-1
            "
          >
            Éditer
          </button>
          <button
            @click="deleteUser(user)"
            class="
              border-2 border-solid border-red-500
              text-red-500
              py-2
              px-5
              bg-white
              rounded-cu
              font-bold
              text-base
              cursor-pointer
              transition
              duration-200
              ease-in-out
              hover:bg-red-500
              hover:text-white
              w-full
              ml-1
            "
          >
            Supprimer
          </button>
        </div>
      </div>
    </div>
    <!-- Null Data patients -->
    <div
      v-if="!users || (users.length == 0 && !loader)"
      class="bg-white 0 px-8 py- w-full flex justify-center items-center"
    >
      <img alt="vide" src="/svg/null_data.svg" class="max-h-300 xl:max-h-500" />
    </div>
    <!-- Modal -->
    <modal
      :title="title"
      :is-active="modal"
      :exit-modal="exitModal"
      :width="630"
    >
      <div class="px-4">
        <!-- FormData -->
        <div class="flex justify-center mb-4">
          <avatar-upload
            :param-file.sync="formData.image"
            :active-upload="true"
            :reSize="true"
          ></avatar-upload>
        </div>
        <ValidationObserver ref="observer">
          <div class="w-full mb-2 my-2">
            <ValidationProvider name="cin" rules="required" id="cin">
              <div slot-scope="{ errors }">
                <input
                  v-model="formData.cin"
                  placeholder="CIN"
                  :class="errors[0] ? 'border border-red-600 border-solid' : ''"
                  class="
                    bg-white
                    py-3
                    px-2
                    border border-solid border-gray-300
                    rounded-cu
                    w-full
                  "
                />
                <p v-if="errors[0]" class="text-red-700 text-left">
                  Veuillez remplir tous les champs
                </p>
              </div>
            </ValidationProvider>
          </div>
          <div class="flex flex-col lg:flex-row my-2">
            <div class="mr-0 lg:mr-3 w-full mb-2">
              <ValidationProvider
                name="firstName"
                rules="required"
                id="firstName"
              >
                <div slot-scope="{ errors }">
                  <input
                    v-model="formData.firstName"
                    placeholder="Prénom"
                    :class="
                      errors[0] ? 'border border-red-600 border-solid' : ''
                    "
                    class="
                      bg-white
                      py-3
                      px-2
                      border border-solid border-gray-300
                      rounded-cu
                      w-full
                    "
                  />
                  <p v-if="errors[0]" class="text-red-700 text-left">
                    Veuillez remplir tous les champs
                  </p>
                </div>
              </ValidationProvider>
            </div>
            <div class="w-full mb-2">
              <ValidationProvider
                name="lastName"
                rules="required"
                id="lastName"
              >
                <div slot-scope="{ errors }">
                  <input
                    v-model="formData.lastName"
                    placeholder="Nom"
                    :class="
                      errors[0] ? 'border border-red-600 border-solid' : ''
                    "
                    class="
                      bg-white
                      py-3
                      px-2
                      border border-solid border-gray-300
                      rounded-cu
                      w-full
                    "
                  />
                  <p v-if="errors[0]" class="text-red-700 text-left">
                    Veuillez remplir tous les champs
                  </p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="flex flex-col lg:flex-row my-2">
            <div class="mr-0 lg:mr-3 w-full mb-2">
              <ValidationProvider name="job" rules="required" id="job">
                <div slot-scope="{ errors }">
                  <input
                    v-model="formData.job"
                    placeholder="Fonction"
                    :class="
                      errors[0] ? 'border border-red-600 border-solid' : ''
                    "
                    class="
                      bg-white
                      py-3
                      px-2
                      border border-solid border-gray-300
                      rounded-cu
                      w-full
                    "
                  />
                  <p v-if="errors[0]" class="text-red-700 text-left">
                    Veuillez remplir tous les champs
                  </p>
                </div>
              </ValidationProvider>
            </div>
            <div class="w-full mb-2">
              <ValidationProvider
                name="phone"
                rules="required|numeric"
                id="phone"
              >
                <div slot-scope="{ errors }">
                  <input
                    v-model="formData.mobile"
                    placeholder="Téléphone"
                    :class="
                      errors[0] ? 'border border-red-600 border-solid' : ''
                    "
                    class="
                      bg-white
                      py-3
                      px-2
                      border border-solid border-gray-300
                      rounded-cu
                      w-full
                    "
                  />
                  <p v-if="errors[0]" class="text-red-700 text-left">
                    Numéro de téléphone non valide
                  </p>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="flex flex-col lg:flex-row my-2">
            <div class="mr-0 lg:mr-3 w-full mb-2">
              <ValidationProvider
                name="email"
                rules="required|email"
                id="email"
              >
                <div slot-scope="{ errors }">
                  <input
                    v-model="formData.email"
                    placeholder="Email"
                    :class="
                      errors[0] ? 'border border-red-600 border-solid' : ''
                    "
                    class="
                      bg-white
                      py-3
                      px-2
                      border border-solid border-gray-300
                      rounded-cu
                      w-full
                    "
                  />
                  <p v-if="errors[0]" class="text-red-700 text-left">
                    Adresse Email non valide
                  </p>
                </div>
              </ValidationProvider>
            </div>
            <div class="w-full mb-2">
              <input
                v-model="formData.password"
                type="password"
                placeholder="Mot de passe"
                class="
                  bg-white
                  py-3
                  px-2
                  border border-solid border-gray-300
                  rounded-cu
                  w-full
                "
              />
            </div>
          </div>
        </ValidationObserver>
        <hr class="border border-solid border-gray-300 my-5" />
        <!-- Buttons -->
        <div class="flex justify-center w-full">
          <button
            class="
              font-EffraR
              py-2
              px-5
              mb-3
              border-2 border-solid border-nblue
              bg-white
              rounded-cu
              text-nblue
              cursor-pointer
              w6/12
            "
            @click="formData._id ? onEdit() : onSave()"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
/* eslint-disable */
const modal = () => import("@/views/global-components/modal.vue");
const avatarUpload = () => import("@/views/global-components/uploadAvatar.vue");
const loader = () => import("@/views/global-components/loader");
export default {
  components: { modal, avatarUpload, loader },
  data() {
    return {
      formData: {},
      modal: false,
      title: "Créer un nouvel utilisateur",
      loader: true,
      users: null
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const { ok, data, message } = await this.$store.dispatch(
          "pharmacy/GET_EMPLOYES"
        );
        if (ok) this.users = data;
        else
          this.$vs.notify({
            time: 4000,
            text: message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-times"
          });
      } finally {
        this.loader = false;
      }
    },
    exitModal: function() {
      this.modal = !this.modal;
      this.title = "Créer un nouvel utilisateur";
    },
    onSave: async function() {
      try {
        this.loader = true;
        const isValid = await this.$refs.observer.validate();
        if (!isValid) return;
        const { ok, message, data } = await this.$store.dispatch(
          "pharmacy/STORE_EMPLOYE",
          this.formData
        );
        if (ok) {
          this.users.push(data);
          this.formData = {};
          this.modal = false;
        } else
          this.$vs.notify({
            time: 4000,
            text: message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-times"
          });
      } catch (err) {
        this.$vs.notify({
          time: 4000,
          text: err,
          color: "danger",
          iconPack: "feather",
          icon: "icon-times"
        });
      } finally {
        this.loader = false;
      }
    },
    editUser: async function(user) {
      user.password = "";
      this.formData = user;
      this.modal = true;
      this.title = "Modifier Utilisateur";
    },
    onEdit: async function() {
      try {
        this.loader = true;
        const isValid = await this.$refs.observer.validate();
        if (!isValid) return;
        const { ok, message } = await this.$store.dispatch(
          "pharmacy/UPDATE_EMPLOYE",
          this.formData
        );
        if (ok) {
          this.formData = {};
          this.modal = false;
        } else
          this.$vs.notify({
            time: 4000,
            text: message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-times"
          });
      } catch (err) {
        this.$vs.notify({
          time: 4000,
          text: err,
          color: "danger",
          iconPack: "feather",
          icon: "icon-times"
        });
      } finally {
        this.loader = false;
      }
    },
    async deleteUser(user) {
      if (confirm("Vous ếtes sur que vous voulez supprimer ce compte ?")) {
        this.loader = true;
        try {
          const {
            ok,
            message
          } = await this.$store.dispatch("pharmacy/DELETE_EMPLOYE", {
            id: user._id
          });
          if (ok) {
            await this.loadData();
          } else
            this.$vs.notify({
              time: 4000,
              text: message,
              color: "#ca0b00",
              iconPack: "feather",
              icon: "icon-check"
            });
        } catch (err) {
          this.$vs.notify({
            time: 4000,
            text: err,
            color: "#ca0b00",
            iconPack: "feather",
            icon: "icon-check"
          });
        } finally {
          this.loader = false;
        }
      }
    }
  }
};
</script>
